.heroabout {
    background: #014E64;
    top: 95px;
    height: 400px;
}

.menumain {
    line-height: 26px;
    font-size: 1rem;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 30px;

}

.mission{
    margin-top: 50px;
    margin-bottom: 220px;
    line-height: 26px;
    font-weight: 400;
    font-size: 1rem;
}

.careers{
    margin-top: 50px;
    margin-bottom: 240px;
    line-height: 26px;
    font-weight: 400;
    font-size: 1rem;

}

.ContactUs{
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 150px;
    margin-bottom: 150px;
}

.AboutUs{
    margin-top: 50px;
    margin-bottom: 220px;
    line-height: 26px;
    font-weight: 400;
    font-size: 1rem;
}
