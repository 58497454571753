/** hero section **/

.hero {
    background: #014E64;
    top: 95px;
    height: 604px;
}

.hero-image-main {
    position: absolute;
    right: 10%;
    top: 150px;
    height: 476px;
    width: 540px ;
}

.hero-image-vector1 {
    position: absolute;
    right: 0%;
    top: 450px;
}

.hero-image-vector2 {
    position: absolute;
    left: 1%;
    top: 95px;
    height: 400px;
    width: 400px;
    opacity: 10%;
}

.hero-image-vector3 {
    position: absolute;
    left: 50%;
    top: 140px;
    height: 62px;
    width: 62px;
}

.hero-image-vector4 {
    position: absolute;
    left: 30%;
    top: 540px;
    height: 62px;
    width: 62px;
}

.herotxt {
    position: absolute;
    left: 10%;
    top: 260px;
    height: 128px;
    width: 720px;
    font-size: 16px;
    font-weight: 600;
    color: white;
}

.herotxt h1 {
    font-size: 36px;
    font-weight: 600;
}

.herotxt p {
    text-align: left;
    font-size: 14px;
    font-weight: 200;
}

/** Products Section */

.homebox1 {
    height: 600px;
    line-height: 26px;
    font-weight: 400;
}

.productcontent {
    display: flex;
    align-items: center;
    margin-left: 10%;
    margin-right: 10%;

}

.productimg {
    width: 50%;
    display: flex;
    justify-content: right;
    padding-right: 20px;
}

.productdesc {
    width: 50%;
    padding-left: 20px;
    line-height: 26px;
    font-weight: 400;
    font-size: 1rem;
    align-items: center;
}

.productdesc p {
    padding-bottom: 20px;
    text-align: left;
    font-size: 22px;
}

/** Home Box2 **/

.homebox2 {
    background: #F2F4F3;
    margin-top: 0px;
    padding-bottom: 20px;
    font-weight: 400;
    line-height: 26px;
    font-size: 1rem;
}

.homebox3 {
    margin-top: 30px;
    line-height: 26px;
    font-weight: 400;
    font-size: 1rem;
}

/** Home Box4 **/
.homebox4 {
    background: #F2F4F3;
    height: fit-content;
    margin-top: 30px;
    font-weight: 400;
    padding-bottom: 0px;
    font-size: 1rem;
}

/** Home Box5 **/
.homebox5 {
    background: #F2F4F3;
    height: 100px;
    font-size: 1rem;

}

.HomeBoxTest{
    background: black;
    height:800px;
    font-size: 1rem;
}

.HomeBoxTestTittle {
    position: absolute;
    left: 10%;
    width: 60%;
    font-size: 22px;
    font-weight: 600;
    color: white;
    margin-top: 150px;
    justify-content: center;

}

.HomeBoxTestdesc{
    position: absolute;
    left: 10%;
    width: 60%;
    font-weight: 400;
    font-size: 24px;
    color: white;
    justify-content: left;
    align-items: center;
    margin-top: 70px;
}



@media (max-width: 480px) {
    .hero-image-main {
        right: 10%;
        left: 1%;
        top: 90px;
        height: 476px;
        width: 440px ;
    }
    
    .herotxt {
        top: 480px;
        width: 320px;
    }


    .herotxt h1 {
        font-size: 24px;
        font-weight: 600;
        word-wrap:normal;
    }
    .homebox1 {
        height: 1050px;
    }

    .HomeBoxTestdesc{
        margin-top: 20px;
        width: 80%;
        font-size: 18px;
    }
    
    .productcontent {
        flex-direction: column;
        align-items: center;
        margin-left: 10%;
        margin-right: 10%;
    }

    .productimg {
        width: 100%;
        padding-right: 10px;
    }
    .productdesc {
        width: 100%;
    }
    .productdesc p {
        font-size: 18px;
    }

}
