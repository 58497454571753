/** hero section **/

.hero-services {
    background: #014E64;
    top: 95px;
    height: 400px;
}

.hero-services-image {
    position: absolute;
    right: 10%;
    top: 150px;
    height: 300px;
}

.hero-services-image2 {
    position: absolute;
    left: 0%;
    top: 150px;
    height: 300px;
}

.hero-services-text {
    position: absolute;
    margin-top: 175px;
    margin-left: 10%;
    font-size: 16px;
    font-weight: 600;
    color: white;
}

/** AI section **/

.AISolutions {
    height: 1100px;
    line-height: 26px;
    font-weight: 400;
}


.AIServices {
    height: 630px;
    background:#F2F4F3;
    line-height: 26px;
    font-weight: 400;
}

.principles {
    height: 600px;
    line-height: 26px;
    font-weight: 400;
}

.techStack {
    height: 450px;
    line-height: 26px;
    font-weight: 400;
}

.servicescontent {
    display: flex;
    align-items: center;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 50px;
    font-size: 1rem;
    line-height: 1.5;
}

.serviceimg {
    width: 50%;
    display: flex;
    justify-content: left;
    padding-left: 20px;
}

/** CTO  section **/

.CTOServices {
    height: 280px;
    line-height: 26px;
    font-weight: 400;
}

.CTOServicesCore {
    height: 400px;
    line-height: 26px;
    font-weight: 400;
}

.CTOServicesImg {
    height: 320px;
    display: flex;
    justify-content: center;

}

.CTOServicesItem {
    background: #F2F4F3;
    height: fit-content;
    margin-top: 30px;
    font-weight: 400;
    padding-bottom: 30px;
    font-size: 1rem;
}

/** Big data section **/


.BigdataServices {
    height: 400px;
    line-height: 26px;
    font-weight: 400;
}

.BigdataServicesImg {
    height: 320px;
    display: flex;
    justify-content: center;
}

.BigdataServicesCore{
    height: 600px;
    line-height: 26px;
    font-weight: 400;

}