.heroindus {
    background: #014E64;
    top: 95px;
    height: 400px;
}

.hero-image-indus {
    position: absolute;
    right: 10%;
    padding-top: 30px;
    height: 390px;
    width: 430px;

}

.SmartIrrigation {
  
    background: #F2F4F3;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;
    padding-bottom: 20px;
}

.sicontent {
    display: flex;
    align-items: center;
    margin-left: 10%;
    margin-right: 10%;
    padding-bottom: 30px;
    font-size: 1rem;
    line-height: 1.5;
}

.DetectingPD {
    line-height: 26px;
    font-weight: 400;
    align-items: center;
    margin-left: 10%;
    margin-right: 10%;
    padding-bottom: 20px;
    font-size: 1rem;
}