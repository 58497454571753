/** hero section **/

.hero-product {
    background: #014E64;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;
    
}
 .hero-product-img
 {
     
    display: flex;
    justify-content: flex-start;
 }
.hero-product-text {
 
    display: flex;
    justify-content: end;
     font-size: 16px;
    font-weight: 600;
    color: white;
}

.GPDBcontent {
    align-items: center;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 50px;
}

.GPDBImg {
    display: flex;
    justify-content: center;
    align-items: center;
}

.GPDBDesc {
    padding-top: 50px;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    line-height: 1.5;
}

.GPDBDesc h2 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.GPDBDesc p {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
}


.GreenPolyDBBox2 {
    background: #F2F4F3;
    padding-bottom: 30px;
    margin-top: 30px;
    font-weight: 400;
   
}

.GreenPolyDBBox3{
   
    line-height: 26px;
    margin-top: 30px;
    font-weight: 400;
    font-size: 1rem;
}

.GreenPolyDBBox4{
    background: #F2F4F3;
    
    margin-top: 30px;
    font-weight: 400;
}

.GreenPolyDBBox5{
    background: #014E64 ;
    color: white; 
    line-height: 26px;
    margin-top: 30px;
    font-weight: 400;
    font-size: 1rem;
}