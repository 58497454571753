* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}


/** Header **/

/**header {}**/

/** Navbar **/
.navbar {
  display: flex;
  align-items: center;

  margin-left: 10%;
  margin-right: 10%;
  height: 80px;
  padding: 5px 5px;
}

.logo {
  width: 50%;
  display: flex;
  align-items: center;

}

.logo img {
  width: 220px;
  height: 50px;
}

.menubar {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: right;
}

.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 10px 5px;
  color: #004E64;
  font-family: inherit;
}

.menu-items {
  position: relative;
  font-size: 14px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  font-family: inherit;

}

button span {
  margin-left: 3px;
}

.menu-items>a,
.menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: #f2f4f3;
  color: black;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;

}

.dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 15rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #f2f4f3;
  color: #004E64;
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}



/** content **/


/** Alice Corousel css **/


.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: none;
}

li.alice-carousel__dots-item {
  background-color: gray !important;

}

li.alice-carousel__dots-item.__active {
  background-color: black !important;
}

.alice-carousel__stage-item {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}


/** Footer **/

.copywrite {

  height: 36px;
  justify-content: space-between;
  margin-left: 10%;
  margin-right: 10%;
  color: #424242;
  font-size: 10px;

}

.open {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px 5px;
  color: white;
  background-color: black;
  font-family: inherit;
  z-index: 999;
}


.burgermenu {
  display: none;
  position: absolute;
  top: 1.25rem;
  right: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 2rem;
}

.burgermenu span {
  height: 0.4rem;
  width: 100%;
  background-color: black;
  border-radius: 0.2rem;
}



@media (max-width: 480px) {
  .menus {
    display: none;
  }

  .burgermenu {
    display: flex;
  }

  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .menubar {
    width: 100%;
  }

  .menus {
    display: none;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.25rem;
  }

  .menu-items {
    font-size: 16px;
  }


}